<template>
  <section>
    <v-list two-line v-if="cliente.fornitori.length">
      <template v-for="(fornitore, index) in cliente.fornitori">
        <v-list-tile
          :key="'tile-'+fornitore.nome">
          <v-list-tile-content>
            <v-list-tile-title v-text="fornitore.nome"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider :key="fornitore.nome" v-if="index < fornitore.length - 1" />
      </template>
    </v-list>
  </section>
</template>
<script>
import { $EventBus } from '@/eventBus'
export default {
  data: () => ({
    cliente: {}
  }),
  mounted () {
    this.$plsqlRead('cliente', this.$route.params.id)
      .then(response => {
        this.cliente = response.data
      })
      .catch(err => {
        console.log(err)
        $EventBus.$emit('message', {
          type: 'error',
          text: 'Errore lettura cliente'
        })
      })
  }
}
</script>
